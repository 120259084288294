import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const WorkPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Work`
  const posts = data.allMarkdownRemark.nodes

  const params = {
    inc: 0.02,
    zInc: 0.00005,
  }

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle} params={params}>
        <Seo title="Work" />
        <div className="main-content">
          <p>No blog posts found.</p>
        </div>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} params={params}>
      <Seo title="Work" />
      <div className="main-content">
        <ol style={{ listStyle: `none` }}>
          <li>
            My work to mint on{" "}
            <a
              aria-label="Weblink FX Hash"
              href="https://www.fxhash.xyz/u/BobeldijkArt/creations"
              target="_blank"
            >
              FX Hash
            </a>
            , to buy on{" "}
            <a
              aria-label="Weblink OBJKT"
              href="https://objkt.com/profile/bobeldijkart/created"
              target="_blank"
            >
              OBJKT{" "}
            </a>
            and to view on{" "}
            <a
              aria-label="Weblink Deca"
              href="https://deca.art/BobeldijkArt/organiture"
              target="_blank"
            >
              Deca
            </a>
          </li>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug
            let pictureSrc = post.frontmatter.picture
            let imageData = data.allFile.edges.find(
              n => n.node.relativePath === pictureSrc
            ).node.childImageSharp.gatsbyImageData

            return (
              <li key={post.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <div className="bio">
                      <Link to={post.fields.slug} itemProp="url">
                        <GatsbyImage
                          className="bio-avatar"
                          layout="constrained"
                          image={imageData}
                          alt="Project picture"
                        />
                      </Link>
                      <p>
                        <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>{" "}
                        {post.frontmatter.description || post.excerpt}
                      </p>
                      {/* <h2> */}
                      {/* <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link> */}
                      {/* </h2> */}
                      {/* <section > */}
                      {/* <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      /> */}
                      {/* </section> */}
                    </div>
                  </header>
                </article>
              </li>
            )
          })}
        </ol>
      </div>
    </Layout>
  )
}

export default WorkPage

export const pageQuery = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO]
              placeholder: BLURRED
              width: 80
              height: 80
              quality: 100
            )
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM, YYYY")
          title
          description
          picture
        }
      }
    }
  }
`
